import Ajv from 'ajv'
import addFormats from 'ajv-formats'

const ajv = new Ajv(
  {
    allErrors: true,
    allowDate: true,
    verbose: true,
    formats: {
      duration: true,
      html: true
    }
  }
)

addFormats(ajv)
// regEx-Format (RFC-2822) found here: https://www.w3resource.com/javascript/form/email-validation.php
// eslint-disable-next-line no-control-regex
const emailFormat = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*)@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
ajv.addFormat('email', emailFormat)
ajv.addKeyword('_tdh_meta')

export default ajv
