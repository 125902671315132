function getEntryData ({ state, commit }, [content, id]) {
  const vm = this._vm
  const api = this._vm.$config.rest_url
  const referencePath = this._vm.$config.reference_path
  return new Promise((resolve, reject) => {
    if (!state.data[content]) {
      state.data[content] = {}
    }
    if (state.data[content][id]) {
      return resolve(state.data[content][id])
    } else {
      const url = api + referencePath + content + '/' + id + '/'
      vm.axios.get(url)
        .then(function (response) {
          commit(
            'setEntryData',
            [content, id, response.data]
          )
          return resolve(state.data[content][id])
        }).catch(function (err) {
          return reject(err)
        })
    }
  })
}

function setNewEntry ({ state, commit }, [schema, content, id, data = {}]) {
  return new Promise((resolve, reject) => {
    if (!state.data[content]) {
      state.data[content] = {}
    }
    const entryObj = { data, field_permissions: {}, delete: true }
    schema.fields.forEach(el => {
      entryObj.data[el.key] = data[el.key] ? data[el.key] : null
    })
    commit(
      'setEntryData',
      [content, id, entryObj]
    )
    resolve(state.data[content][id])
  })
}

export default {
  getEntryData,
  setNewEntry
}
