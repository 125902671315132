import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    // needs property that is filled with content because of vuex workflow
    data: {}
  },
  mutations,
  actions,
  getters
}
