function setEntryHistory (state, [id, value]) {
  // TODO: replace with lodash deepcopy once merge has occurred
  const data = JSON.parse(JSON.stringify(value))
  // const data = _clonedeep(value)
  state.data[id] = data
}

function addEntryHistory (state, [id, value]) {
  // TODO: replace with lodash deepcopy once merge has occurred
  const data = JSON.parse(JSON.stringify(value))
  // const data = _clonedeep(value)

  if (!state.data[id]) {
    state.data[id] = []
  }
  state.data[id].splice(-1, 0, ...data)
}

function resetState (state) {
  Object.assign(state, { data: {} })
}

export default {
  setEntryHistory,
  addEntryHistory,
  resetState
}
