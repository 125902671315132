function getSchema ({ state, commit }, content) {
  const vm = this._vm
  const api = vm.$config.rest_url
  const reference_path = vm.$config.reference_path
  return new Promise((resolve, reject) => {
    if (state.schemas[content]) {
      resolve(state.schemas[content])
    } else {
      const url = api + reference_path + content + '/'
      vm.axios.options(url)
        .then(function (response) {
          commit(
            'setSchema',
            [content, response.data]
          )
          resolve(state.schemas[content])
        }).catch(function (err) {
          return reject(err)
        })
    }
  })
}

function getSearchSchema ({ state, commit }, content) {
  const vm = this._vm
  const api = vm.$config.rest_url
  return new Promise((resolve, reject) => {
    if (state.searchSchemas[content]) {
      resolve(state.searchSchemas[content])
    } else {
      const url = api + '/ui/search/' + content + '/'
      vm.axios.options(url)
        .then(function (response) {
          commit(
            'setSearchSchema',
            [content, response.data]
          )
          resolve(state.searchSchemas[content])
        }).catch(function (err) {
          return reject(err)
        })
    }
  })
}

function getPublicSchema ({ state, commit }, content) {
  const vm = this._vm
  const api = vm.$config.rest_url
  const reference_path = vm.$config.reference_path
  return new Promise((resolve, reject) => {
    if (state.schemas[content]) {
      resolve(state.schemas[content])
    } else {
      const url = api + '/Revision/options/'
      vm.axios.options(url)
        .then(function (response) {
          commit(
            'setSchema',
            [content, response.data]
          )
          resolve(state.schemas[content])
        }).catch(function (err) {
          return reject(err)
        })
    }
  })
}

export default {
  getSchema,
  getSearchSchema,
  getPublicSchema
}
