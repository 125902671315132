import _clonedeep from 'lodash/cloneDeep'

function setEntryData (state, [content, variable, value]) {
  // deep-copy necessary to prevent cached promise to be mutated
  // const data = JSON.parse(JSON.stringify(value))
  const data = _clonedeep(value)
  state.data[content][variable] = data
}

function addElement (state, [content, variable, value]) {
  state.data[content][variable].push(value)
}

function removeEntryData (state, [content, id]) {
  if (!state.data?.[content]?.[id]) return
  delete state.data[content][id]
}

function resetState (state) {
  Object.assign(state, { data: {} })
}

export default {
  setEntryData,
  addElement,
  removeEntryData,
  resetState
}
