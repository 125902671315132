import xhrAdapter from 'axios/lib/adapters/xhr'

const cache = {}

export default config => {
  return request => {
    const now = Date.now()
    Object.keys(cache)
      .filter(k => now - cache[k].timestamp > config.axiosAdapter.ttl)
      .forEach(k => { delete cache[k] })
    if (!request.method.match(/get|options/i)) {
      if (request.method.match(/delete|patch/i) && cache[request.url]) {
        delete cache[request.url]
      }
      return xhrAdapter(request)
    }
    const key = request.url
    if (cache[key]) {
      return cache[key].promise
    }
    cache[key] = {
      timestamp: now,
      promise: xhrAdapter(request)
    }
    cache[key].promise.catch(() => {
      delete cache[key]
    })
    return cache[key].promise
  }
}
