function setSchema (state, [variable, value]) {
  const data = JSON.parse(JSON.stringify(value))
  state.schemas[variable] = data
}

function setSearchSchema (state, [variable, value]) {
  state.searchSchemas[variable] = value
}

function resetState (state) {
  Object.assign(state, { schemas: {}, searchSchemas: {} })
}

export default {
  setSchema,
  setSearchSchema,
  resetState
}
