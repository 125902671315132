function getMessages ({ state, commit }, [content, type]) {
  if (state.data[content] && state.data[content][type]) {
    return Promise.resolve(state.data[content][type])
  }
  const vm = this._vm
  const results = []
  return (function getMessagesIncremental (url) {
    return vm.axios.get(url).then(({ data }) => {
      results.splice(-1, 0, ...data.results)
      const next = (data.next)
        ? vm.$config.rest_url + '/ui' + data.next.split('/ui')[1]
        : data.next
      return next ? getMessagesIncremental(next) : Promise.resolve()
    })
  })(
    vm.$config.rest_url + vm.$config.reference_path + 'Message/?id__startswith=' + [content, type].join('.')
  ).then(() => {
    commit('addMessageData', [content, type, results])
    return Promise.resolve(state.data[content][type])
  })
}

export default {
  getMessages
}
