function setContentType (state, [variable, value]) {
  const data = JSON.parse(JSON.stringify(value))
  state.contenttype[variable] = data
}

function addContentType (state, [variable, value]) {
  const data = JSON.parse(JSON.stringify(value))
  state.contenttype[variable].results.push(data)
}

function updateContentType (state, [variable, value]) {
  for (const [entryKey, entryVal] of Object.entries(state.contenttype)) {
    if (entryKey.split('/')[0] === variable) {
      const entry = entryVal.results.find(el => el.id === value.id)
      if (entry) {
        for (const [key] of Object.entries(entry)) {
          entry[key] = value[key]
        }
      }
    }
  }
}

function removeContentType (state, [content]) {
  delete state.contenttype[content]
}

function resetState (state) {
  Object.assign(state, { contenttype: {} })
}

export default {
  setContentType,
  addContentType,
  updateContentType,
  removeContentType,
  resetState
}
