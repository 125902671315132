import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import entries from './entries'
import schemas from './schemas'
import contenttypes from './contenttypes'
import additionaldata from './additionaldata'
import messages from './messages'
import history from './history'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  token: localStorage.getItem('token') || '',
  loggedIn: false,
  versionIms: null,
  lastRequestTimestamp: false,
  user: {},
  layoutschemas: {},
  entrydata: {},
  timeout: 4 * 15 * 60 * 1000,
  activeLoginScreen: false,
  locale: 'de-DE',
  editorTabbedView: false,
  logoutBlock: [],
  logoutInProgress: false,
  logoutPromise: null,
  unsavedChanges: {},
  pollingIntervals: []
}

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    schemas: state.schemas,
    entries: state.entries,
    contenttypes: state.contenttypes,
    messages: state.messages,
    loggedIn: state.loggedIn,
    lastRequestTimestamp: state.lastRequestTimestamp,
    user: state.user,
    versionIms: state.version,
    locale: state.locale,
    additionaldata: state.additionaldata,
    unsavedChanges: state.unsavedChanges
  })
})

const mutations = {
  initialiseStore (state) {
    if (localStorage.getItem('loginStatus') === 'true') {
      state.loggedIn = true
    } else {
      state.loggedIn = false
    }
  },
  setImsVersion (state) {
    const vm = this._vm
    vm.axios.get(vm.$config.rest_url + '/version/')
      .then(response => {
        state.versionIms = response.data
      })
  },
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  set_last_request_timestamp (state, [value]) {
    state.lastRequestTimestamp = value
  },
  set_entry_field_data (state, [id, variable, value]) {
    state.entrydata[id][variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  auth_success (state, user) {
    state.loggedIn = true
    state.lastRequestTimestamp = Date.now()
    localStorage.setItem('loginStatus', 'true')
    state.user = user
  },
  auth_error (state) {
    state.loggedIn = false
    localStorage.setItem('loginStatus', 'false')
  },
  addUnsavedChange (state, tab) {
    state.unsavedChanges[tab.id] = tab
  },
  removeUnsavedChange (state, tabId) {
    if (state.unsavedChanges[tabId]) {
      delete state.unsavedChanges[tabId]
    }
  },
  setLogoutInProgress (state, status) {
    state.logoutInProgress = status
  },
  logout (state) {
    state.loggedIn = false
    sessionStorage.removeItem('RouterTab:restore:')
    sessionStorage.removeItem('RouterTab:restore:/:' + state.user.user.id)
    localStorage.setItem('loginStatus', 'false')
    state.layoutschemas = {}
    state.entrydata = {}
    state.user = null
    state.logoutInProgress = false
    state.sidebarMinimize = false
    state.asideShow = false
    state.unsavedChanges = {}
  },
  toggleEditorTabbedView (state) {
    state.editorTabbedView = !state.editorTabbedView
  },
  addPollingInterval (state, intervalID) {
    state.pollingIntervals.push(intervalID)
  },
  removePollingInterval (state, intervalID) {
    const index = state.pollingIntervals.findIndex(el => el === intervalID)
    state.pollingIntervals.splice(index, 1)
  },
  clearPollingIntervals (state) {
    state.pollingIntervals.forEach(id => clearInterval(id))
  }
}

const getters = {
  isLoggedIn: function (state) {
    return state.loggedIn
  },
  isLogoutInProgress: function (state) {
    return state.logoutInProgress
  },
  hasActiveLoginScreen: function (state) {
    return state.activeLoginScreen
  },
  getUnsavedChanges: function (state) {
    return state.unsavedChanges
  },
  checkLoginTimestamp: (state) => () => {
    if (state.lastRequestTimestamp === false || state.lastRequestTimestamp === undefined) {
      return false
    } else if (Date.now() - state.lastRequestTimestamp > state.timeout) {
      return false
    } else { return true }
  },
  getUser: function (state) {
    return state.user
  },
  getServerVersion: function (state) {
    return state.versionIms
  },
  getCurrentUserName: function (state) {
    return state.user.username
  },
  getLocale: function (state) {
    return state.locale
  },
  getCookie: function (state, cname) {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  },
  /**
   * checks if given id is representing a new entry
   * @param {*} state
   * @returns newEntryState
   */
  getNewEntryState: (state) => (id) => {
    const entryId = (typeof id === 'number') ? id.toString() : id
    return entryId.startsWith('NewEntry_')
  }
}

const actions = {
  tabCloseRejected ({ commit, getters, state }, data) {
    if (!getters.isLogoutInProgress) return
    commit('setLogoutInProgress', false)
    commit('setTabsToBeClosed', [])
    if (state.logoutPromise) state.logoutPromise.reject(new Error('logout canceled by user'))
  },
  login ({ commit }, user) {
    const vm = this._vm
    const api = vm.$config.rest_url
    return new Promise(function (resolve, reject) {
      vm.axios(
        {
          url: api + '/user/login/',
          data: user,
          skipAuthRefresh: true,
          headers: { 'X-CSRFToken': getters.getCookie(state, 'csrftoken') },
          method: 'POST',
          withCredentials: true
        })
        .then(function (resp) {
          const user = resp.data
          commit('auth_success', user)
          resolve(resp)
        })
        .catch(function (err) {
          commit('auth_error')
          reject(err)
        })
    })
  },
  logout ({ commit, getters }, data) {
    // const store = this
    const vm = data.vm
    const api = vm.$config.rest_url
    commit('setLogoutInProgress', true)
    commit('clearPollingIntervals')
    vm.axios.get(api + '/user/logout/', { skipAuthRefresh: true })
      .then(() => {
        localStorage.clear()
      })
      .finally(() => {
        commit('entries/resetState')
        commit('additionaldata/resetState')
        commit('contenttypes/resetState')
        commit('schemas/resetState')
        commit('messages/resetState')
        commit('history/resetState')
        commit('set', ['logoutPromise', null])
        commit('logout')
        vm.$router.push('login')
      })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [vuexLocal.plugin],
  modules: {
    entries,
    schemas,
    contenttypes,
    additionaldata,
    messages,
    history
  }
})
