function getEntryHistory ({ state, commit }, [id]) {
  const vm = this._vm
  const url = vm.$config.rest_url + '/ui/History/?content_id=' + id
  return new Promise((resolve, reject) => {
    vm.axios.get(url).then(({ data }) => {
      const idList = []
      data.results.forEach(el => {
        if (!idList.includes(el.user_id)) {
          idList.push(el.user_id)
        }
      })
      if (idList.length > 0) {
        vm.axios.get(vm.$config.rest_url + '/ui/content/base/?ids=' + idList.join(','))
          .then(resp => {
            data.results.forEach(el => {
              el.user_name = resp.data[el.user_id]
            })
          })
          .finally(() => {
            commit('setEntryHistory', [id, data])
            resolve(data)
          })
      } else {
        commit('setEntryHistory', [id, data])
        resolve(data)
      }
    })
  })
}

export default {
  getEntryHistory
}
