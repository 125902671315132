export default {
  methods: {
    getComponentPathByDataKey: function (contextHint = null) {
      const excludeComponents = ['draggable', 'TDHDateRuleTimes', 'TDHGenericWrapper']
      const path = [this]
      const pathTemp = [this]
      while (path[0].$parent) {
        if (excludeComponents.indexOf(path[0]?.$options?.name) === -1) {
          pathTemp.unshift(path[0].$parent)
        }
        path.unshift(path[0].$parent)
      }
      return pathTemp
        .map(p => [p.pathSeg, p.dataKey].filter(p => p !== null && p !== undefined))
        .flat()
    }
  }
}
