import { Loader } from '@googlemaps/js-api-loader'

/**
 * vue plugin for loading the google maps api and store it globally
 * @type {{install(*, *): void}}
 */
const TDHGoogleMapsApiLoaderPlugin = {
  install (Vue, options) {
    const loader = new Loader({
      apiKey: options.apiKey,
      libraries: options.libraries || []
    })
    Vue.prototype.$tdhGoogleMapsApiLoadedPromise = loader.load()
  }
}

export default TDHGoogleMapsApiLoaderPlugin
