function setEntryData (state, [content, variable, parameter, value]) {
  if (!state.data[content]) {
    state.data[content] = {}
  }
  if (!state.data[content][variable]) {
    state.data[content][variable] = {}
  }
  // deep-copy necessary to prevent cached promise to be mutated
  state.data[content][variable][parameter] = JSON.parse(JSON.stringify(value))
}

function removeEntryData (state, [content, id]) {
  if (state.data[content] !== undefined && state.data[content][id] !== undefined) {
    delete state.data[content][id]
  }
}

function updateAdditionalData (state, [variable, value]) {
  for (const [entryKey, entryVal] of Object.entries(state.data)) {
    if (entryKey.split('/')[0] === variable) {
      const entry = entryVal[value.id]
      if (entry) {
        for (const [key] of Object.entries(entry)) {
          entry[key] = value[key]
        }
      }
    }
  }
}

function resetState (state) {
  Object.assign(state, { data: {} })
}

export default {
  setEntryData,
  removeEntryData,
  resetState,
  updateAdditionalData
}
