function getContentType ({ state, commit }, content) {
  const vm = this._vm
  const api = vm.$config.rest_url
  // const uri = new URL(content)
  // const params = uri.searchParams.get('references')
  // const path = uri.pathname.substr(uri.pathname.lastIndexOf('//') + 1) + '/' + params.split('::')[0]
  // const contentTypeName = [path, '/', params.split('::')[1]].join('')
  const contentTypeName = [content.model, '/', content.model_filter].join('')
  return new Promise((resolve, reject) => {
    if (state.contenttype[contentTypeName]) {
      return resolve(state.contenttype[contentTypeName])
    } else {
      const url = [api, '/ui/content/', content.model, '/?fields=id,name&ordering=name&limit=100&', content.model_filter].join('')
      vm.axios.get(url)
        .then(function (response) {
          commit(
            'setContentType',
            [contentTypeName, response.data]
          )
          // commit(
          //   'setContentType',
          //   [contentTypeName, {results:[]}]
          // )
          return resolve(state.contenttype[contentTypeName])
        }).catch(function (error) {
          return reject(error)
        })
    }
  })
}

export default {
  getContentType
}
