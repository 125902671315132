/**
*
 */
export default {
  methods: {
    /**
     * if the type schema contains a "oneOf" section the proper parts are rendered into the form
     * we need to extract the matching properties and return them for the renderer
     * */
    tdhrequest: function (method, urlPostfix, data) {
      const vm = this
      const api = vm.$config.rest_url
      let promise = null
      const url = [api, urlPostfix].join('')
      promise = new Promise((resolve, reject) => {
        vm.axios({
          method,
          url,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': vm.getCookie('csrftoken')
          },
          data
        })
          .then(response => {
            vm.submit_error = false
            resolve(response)
          })
          .catch(error => {
            vm.submit_error = true
            vm.errors = error.response.data
            reject(error)
          })
          .finally(() => { vm.loading = false })
      })
      return promise
    },
    /**
   * helper method for extracting cookie data
   * @param cname
   * @returns {string}
   */
    getCookie: function (cname) {
      const name = cname + '='
      const ca = decodeURIComponent(document.cookie).split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    }
  }
}
