function getSchema (state, name) {
  return state.schemas[name]
}
function getSearchSchema (state, name) {
  return state.searchSchemas[name]
}

export default {
  getSchema,
  getSearchSchema
}
