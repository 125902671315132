function setMessageData (state, [content, type, value]) {
  // TODO: replace with lodash deepcopy once merge has occurred
  const data = JSON.parse(JSON.stringify(value))
  // const data = _clonedeep(value)
  state.data[content][type] = data
}

function addMessageData (state, [content, type, value]) {
  // TODO: replace with lodash deepcopy once merge has occurred
  const data = JSON.parse(JSON.stringify(value))
  // const data = _clonedeep(value)
  if (!state.data[content]) {
    state.data[content] = {}
  }
  if (!state.data[content][type]) {
    state.data[content][type] = []
  }
  state.data[content][type].splice(-1, 0, ...data)
}

function resetState (state) {
  Object.assign(state, { data: {} })
}

export default {
  setMessageData,
  addMessageData,
  resetState
}
